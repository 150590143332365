import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "../../redux/actions";
import Loading from "../common/Loading";

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_API_KEY);
  }, []);

  const handleSubmitMail = async e => {
    if (!nombre) {
      dispatch(showErrorMessage("Las contraseñas nuevas no coinciden"));
    }
    e.preventDefault();
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        nombre,
        telefono,
        email,
        mensaje,
        recipient: "pedroivanfranco@hotmail.com"
      });
      dispatch(
        showSuccessMessage(
          "¡Gracias por enviarnos tu mensaje! Nos pondremos en contacto contigo a la brevedad."
        )
      );
      setEmail("");
      setNombre("");
      setTelefono("");
      setMensaje("");
    } catch (error) {
      dispatch(
        showErrorMessage(
          "Ocurrió un error al intentar registrar tu mensaje, si el problema persiste podés contactarnos por WhatsApp"
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h2>¡Hablemos!</h2>
              <div class="d-flex row justify-content-center w-100">
                <p>
                  Dejanos tu mensaje y nos comunicaremos contigo a la brevedad.
                  O si lo preferís escribinos directamente{" "}
                  <a
                    href=" https://wa.me/59898164065?text=Hola+Agro+Bolsas&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    por WhatsApp
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid padddd contacto">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 padddd">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <form className="main_form">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <TextField
                        className="text-field"
                        placeholder="Nombre"
                        value={nombre}
                        onChange={e => setNombre(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <TextField
                        className="text-field"
                        placeholder="Correo electrónico"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <TextField
                        className="text-field"
                        placeholder="Teléfono"
                        value={telefono}
                        onChange={e => setTelefono(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <TextField
                        className="text-field-mensaje"
                        placeholder="Mensaje"
                        value={mensaje}
                        onChange={e => setMensaje(e.target.value)}
                        multiline
                        rows={4}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <button onClick={handleSubmitMail} className="send">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width="540"
                      height="500"
                      id="gmap_canvas"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3884.488830185498!2d-56.3403591!3d-34.837646500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a1d79702dfbf47%3A0xdb1098294ac2ea76!2sAgro%20Bolsas!5e1!3m2!1ses!2suy!4v1738774186167!5m2!1ses!2suy"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    ></iframe>

                    <br />

                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacto;
