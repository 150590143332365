import React, { useEffect, useRef, useState } from "react";
import ListadoProductos from "./listadoProductos";
import Carrusel from "./carrusel";
import {
  ObtenerCategorias,
  ObtenerElementosCarrusel
} from "../../services/HttpBaseService";
import { useRequestHandler } from "../../utils/useRequestHandler";
import { Button, TextField } from "@mui/material";
import { TiThMenu } from "react-icons/ti";
import Contacto from "./contacto";
import { useSelector } from "react-redux";

const HomePage = () => {
  document.title = "Agro Bolsas";
  const [elementosCarrusel, setElementosCarrusel] = useState([]);
  const sendRequest = useRequestHandler();
  const [productoBusqueda, setProductoBusqueda] = useState("");
  const [productoBusquedaConfirm, setProductoBusquedaConfirm] = useState("");
  const productosRef = useRef();
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState();
  const categoriaStore = useSelector(store => store.categoria);
  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    sendRequest(ObtenerElementosCarrusel, [], data => {
      setElementosCarrusel(data.data);
    });

    sendRequest(ObtenerCategorias, [], data => {
      setCategorias(data?.data);
    });
  }, []);

  const buscar = () => {
    setProductoBusqueda("");
    setProductoBusquedaConfirm(productoBusqueda);
    productosRef.current?.scrollIntoView();
  };

  useEffect(() => {
    handleCategoriaSelect(categorias.find(x => x.id == categoriaStore));
  }, [categoriaStore]);

  const handleCategoriaSelect = categoria => {
    setCategoriaSelected(categoria);
  };

  return (
    <div className="main-layout">
      <a
        href=" https://wa.me/59898164065?text=Hola+Agro+Bolsas&type=phone_number&app_absent=0"
        className="whatsapp"
        target="_blank"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      <a href="/admin" className="administrar-login">
        admin
      </a>
      <div className="mobile dropdown">
        <Button
          onClick={() => setMenuVisible(!menuVisible)}
          className="menu-mobile"
        >
          <TiThMenu size={36} color="#4bc714" />
        </Button>
        {menuVisible && (
          <div class="dropdown-content">
            {categorias &&
              categorias.length > 0 &&
              categorias.map(x => (
                <a
                  onClick={() => {
                    handleCategoriaSelect(x);
                    setMenuVisible(!menuVisible);
                  }}
                >
                  {x.nombre}
                </a>
              ))}
          </div>
        )}
      </div>
      <header>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-auto">
                <a href="#">
                  <img
                    style={{ width: "180px" }}
                    src="images/logo.png"
                    alt="logo agro bolsas"
                  />
                </a>
              </div>
              <div className="col row mt-3 mb-2 vias-contacto">
                <a
                  href=" https://wa.me/59898164065?text=Hola+Agro+Bolsas&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <div className="col-auto m-1 row">
                    <div className="col-auto p-1">
                      <img src="icon/whatsapp.svg" alt="WhatsApp" />
                    </div>
                    <div className="col m-1 p-1 dato-contacto-header">
                      098164065
                    </div>
                  </div>
                </a>
                <a href="mailto:ventas@agrobolsas.com" target="_blank">
                  <div className="col-auto m-1 row">
                    <div className="col-auto p-1">
                      <img src="icon/email.svg" alt="Email" />
                    </div>
                    <div className="col m-1 p-1 dato-contacto-header">
                      ventas@agrobolsas.com
                    </div>
                  </div>
                </a>
                <a href="tel:22092240">
                  <div className="col-auto m-1 row">
                    <div className="col-auto p-1">
                      <img src="icon/phone.svg" alt="Teléfono" />
                    </div>
                    <div className="col m-1 p-1 dato-contacto-header">
                      22092240
                    </div>
                  </div>
                </a>
                <a
                  href="https://maps.app.goo.gl/LfwVXoq4hTrmnvB86"
                  target="_blank"
                >
                  <div className="col-auto m-1 row">
                    <div className="col-auto p-1">
                      <img src="icon/location.svg" alt="Ubicación" />
                    </div>
                    <div className="col m-1 p-1 dato-contacto-header">
                      Camino O'Higgins 9325
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="row w-100 menu-principal">
              <div className="col-md-12 location_icon_bottum">
                <div className="row">
                  <div className="col-md-8">
                    <div className="menu-area">
                      <div className="limit-box">
                        <nav className="main-menu">
                          <ul className="menu-area-main">
                            <li>
                              <a href="#" className=" dato-contacto-header">
                                Inicio
                              </a>
                            </li>
                            <li className="dropdown">
                              <a
                                href="#productos"
                                className=" dato-contacto-header"
                              >
                                Productos
                              </a>
                              <div class="dropdown-content">
                                {categorias &&
                                  categorias.length > 0 &&
                                  categorias.map(x => (
                                    <a onClick={() => handleCategoriaSelect(x)}>
                                      {x.nombre}
                                    </a>
                                  ))}
                              </div>
                            </li>
                            <li>
                              <a
                                href="#about"
                                className=" dato-contacto-header"
                              >
                                ¿Quiénes somos?
                              </a>
                            </li>
                            <li>
                              <a
                                href="#contact"
                                className=" dato-contacto-header"
                              >
                                Contacto
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                    <div className="search">
                      <TextField
                        size="small"
                        onKeyUp={e => {
                          if (e.key === "Enter") {
                            buscar();
                          }
                        }}
                        className="text-field-buscador"
                        placeholder="Buscar productos"
                        value={productoBusqueda}
                        onChange={e => setProductoBusqueda(e.target.value)}
                      />
                      <Button class="btn-buscar" onClick={() => buscar()}>
                        <img src="images/search_icon.png" alt="Buscar" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {elementosCarrusel?.length > 0 && (
        <section className="slider_section">
          <Carrusel items={elementosCarrusel} />
        </section>
      )}

      <div ref={productosRef} id="productos">
        <ListadoProductos
          textoBusquedaParam={productoBusquedaConfirm}
          categoriaSelectedParam={categoriaSelected}
          categorias={categorias}
          productosRef={productosRef}
        />
      </div>
      <div id="about" className="offer-bg about">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 co-sm-l2">
              <div className="about_box">
                <h2>
                  <br />
                  ¿Quiénes<strong className="black"> somos</strong>?
                </h2>
                <p>
                  Somos Agro Bolsas, una empresa dedicada a la importación y
                  distribución de productos para las empresas agrícolas,
                  ganaderas, frutícolas y hortícolas. Con sede en la ciudad de
                  Montevideo llegando con nuestros productos a todo el Uruguay.
                  Contamos con más de 20 años de experiencia en el rubro,
                  brindándole a nuestros clientes soluciones a la altura de sus
                  exigencias.
                </p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 co-sm-l2">
              <div className="about_img">
                <figure>
                  <img src="images/about.jpg" alt="quienes somos" />
                </figure>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mr-4">
              <div className="about_box">
                <h2>
                  <br />
                  <strong className="black">Misión</strong>
                </h2>
                <p>
                  Brindar productos de calidad en tiempo y forma, adaptándonos a
                  la versatilidad del entorno, para de esta manera brindar el
                  mejor servicio a nuestros clientes.
                </p>
              </div>
            </div>
            <div className="col ml-4">
              <div className="about_box">
                <h2>
                  <br />
                  <strong className="black">Visión</strong>
                </h2>
                <p>
                  Ser el aliado fundamental del productor agrícola, frutícula,
                  hortícola y ganadero. Posicionarnos como la empresa referente
                  de nuestros actuales y potenciales clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="contact ">
        <Contacto />
      </div>

      <footr>
        <div className="footer top_layer">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="address mr-4">
                  <a href="#">
                    <img src="images/logo.png" alt="logo" width="180px" />
                  </a>
                  <p>
                    Somos una empresa dedicada a la importación y distribución
                    de productos para las empresas agrícolas, ganaderas,
                    frutícolas y hortícolas. Contamos con más de 20 años de
                    experiencia en el rubro, brindándole a nuestros clientes
                    soluciones a la altura de sus exigencias.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-navegacion">
                <div className="address pl-4">
                  <h3>Navegación</h3>
                  <ul className="Links_footer ml-3">
                    <li>
                      <img src="icon/3.png" alt="#inicio" />
                      <a href="#">Inicio</a>
                    </li>
                    <li>
                      <img src="icon/3.png" alt="#productos" />
                      <a href="#productos">Productos</a>
                    </li>
                    <li>
                      <img src="icon/3.png" alt="#about" />
                      <a href="#about">¿Quiénes somos?</a>
                    </li>
                    <li>
                      <img src="icon/3.png" alt="#contact" />
                      <a href="#contact">Contacto</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 footer-contacto">
                <div className="address">
                  <h3>Contacto</h3>

                  <ul className="Links_footer">
                    <a
                      href=" https://wa.me/59898164065?text=Hola+Agro+Bolsas&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      <div className="col-auto row">
                        <div className="col-auto p-1">
                          <img
                            src="icon/whatsapp.svg"
                            alt="whatsapp agro bolsas"
                          />
                        </div>
                        <div className="col p-1 dato-contacto-header">
                          098164065
                        </div>
                      </div>
                    </a>
                    <a href="mailto:ventas@agrobolsas.com" target="_blank">
                      <div className="col-auto row">
                        <div className="col-auto p-1">
                          <img src="icon/email.svg" alt="email agro bolsas" />
                        </div>
                        <div className="col p-1 dato-contacto-header">
                          ventas@agrobolsas.com
                        </div>
                      </div>
                    </a>
                    <a href="tel:22092240">
                      <div className="col-auto row">
                        <div className="col-auto p-1">
                          <img
                            src="icon/phone.svg"
                            alt="telefono agro bolsas"
                          />
                        </div>
                        <div className="col p-1 dato-contacto-header">
                          22092240
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://maps.app.goo.gl/LfwVXoq4hTrmnvB86"
                      target="_blank"
                    >
                      <div className="col-auto row">
                        <div className="col-auto p-1">
                          <img
                            src="icon/location.svg"
                            alt="ubicacion agro bolsas"
                          />
                        </div>
                        <div className="col p-1 dato-contacto-header">
                          Camino O'Higgins 9325
                        </div>
                      </div>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-firma w-100 text-center">
            Sitio web desarrollado por{" "}
            <a href="mailto:pedroivanfranco@gmail.com">Pedro Franco</a>
          </div>
        </div>
      </footr>
    </div>
  );
};

export default HomePage;
